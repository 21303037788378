<template>
  <div style="margin: 20px">
    <el-button type="success" v-if="userCan(['technologist', 'control', 'admin'])" style="margin-bottom: 10px" @click="create">Создать</el-button>
    <v-table :fetch="fetchData" name="units" :columns="columns"/>
  </div>
</template>

<script>
import VTable from '@/components/VTable'
import { getIogvs } from '@/api/dictionaries'
import { getViceGovernorsDict } from '@/api/catalogs'
import userCan from '@/utils/checkPermission'
export default {
  components: {
    VTable
  },
  data () {
    return {
      governors: []
    }
  },
  computed: {
    columns () {
      const governors = this.governors
      return [
        {
          field: 'vice_governor_id',
          filter (value) {
            return governors?.find(x => x.id === value)?.title || ''
          },
          items: governors,
          multiple: true
        }
      ]
    }
  },
  mounted () {
    getViceGovernorsDict().then((response) => {
      this.governors = response.data.data
    })
  },
  methods: {
    userCan: (roles) => userCan(roles),
    fetchData: (...args) => getIogvs(...args),
    create () {
      this.$router.push({ name: this.$route.name.replace('list', 'create') })
    }
  }
}
</script>

<style scoped>

</style>
